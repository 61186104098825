:root {
  --font-size-form-error:12px;
  --color-form-error:red;
  --font-size-label:13px;
  --font-size-help-text:10px;
}

label {
  display: block;
  font-weight: 500;
  font-size: var(--font-size-label);
  color: var(--color-label);
  margin-bottom: 8px;
}

.helpText {
  font-size: var(--font-size-help-text);
  margin-top: -5px;
}

// .formEditable .fileUploadBtn {
//   background-color: #e9ecef;

//   &:hover{
//     background-color: #dde0e3;
//   }
// }

.formField {
  > {
    input, select, textarea, div, .form-control, .password-wrapper input  {
      width: 100%;
      min-height: 40px;
      padding: 7px 12px;
      margin: 0;
      border-radius: var(--border-radius-primary);
      border: var(--btn-primary-border);
      color: var(--color-secondary);

      // &:hover{
      //   border: var(--btn-primary-hover-border);
      // }
    }
  }

  .react-tel-input input {
    padding: 7px 12px;
    margin: 10px 0;
    border-radius: var(--border-radius-primary);
    border: 1px solid hsl(0, 0%, 83%);
    color: var(--color-secondary);
  }

  .searchableSelect-dropdown {
    background-color: var(--color-primary);

    /* margin-top: -.5rem; */
    border: 1px solid hsl(0, 0%, 83%);
    position: absolute;
    z-index: 1;
    bottom: -15px;
    top: 55px;
    height: fit-content;
    max-height: 320px;
    overflow-y: auto;
  }
}

.formField.billingFormButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btnWrapper{
    width: fit-content;
  }
  
  button, .btnWrapper{
    float: right;
  }
}

.searchableSelect-option {
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 1px solid hsl(0, 0%, 83%);

  &:hover {
    background-color: #77bee1;
  }
}

input, textarea {
  letter-spacing: .6px;
}

.formField > div {
  padding: 0;
  border: 0;
}

input:disabled, 
textarea:disabled, 
select:disabled, 
.react-tel-input :disabled,
.react-select--is-disabled {
  background-color: var(--background-color-disabled) !important;
  cursor: not-allowed;

  .react-select__control{
    cursor: not-allowed;
  }
}

.react-select--is-disabled{
  pointer-events: auto !important;
}

.formField {
  margin: 12px 0;

  // input, select, textarea, > div, .react-tel-input .form-control {
  //   width: 85%;
  // }
}

.companyInfo .react-tel-input input.form-control {
  width: 85%;
}

.formField {
  &.input-expiryMonth, &.input-expiryYear {
    display: inline-block;
    margin: 0 12px 0 0;
    width: fit-content !important;
    margin: 0;
  }

  &.input-cardCVV {
    display: inline-block;
    margin: 0;
    width: fit-content;
  }
}

/* Form Error Message */

span.formField-errorMessage {
  font-size: var(--font-size-form-error);
  color: var(--color-form-error);
  padding: 3px;
  display: none;
}

input {
  &:invalid[focused="true"] ~ span, &.hasError ~ span, &.invalid ~ span {
    display: block;
  }
}

.formField {
  input, input.form-control, .react-tel-input input {
    &:invalid {
      &:focus, &:focus-visible {
        outline: none !important;
        border: 1px solid #f37f7f;
      }
    }
    
    &:valid {
      &:focus-visible, &:focus {
        outline: none !important;
        border: 1px solid #77bee1;
      }
    }

    &.invalid, &.invalid-number {
      &:focus, &:focus-visible {
        outline: none !important;
        border: 1px solid #f37f7f;
      }
    }
  }

  .react-tel-input {
    width: 100%;
    // margin-bottom: 25px;

    .flag-dropdown {
      border-radius: var(--border-radius-primary) 0 0 var(--border-radius-primary);
    }

    input.form-control {
      width: 100%;
      height: 40px;
      border: var(--btn-primary-border);
      letter-spacing: .6px;
      padding-left: 48px;
      border-left: 0;
      background-color: inherit;
    }
  }
}

.radioGroup{
  display: flex;
  align-items: center;
  height: 40px;
  gap: 12px;

  .radio{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    // border: var(--border);
    border-radius: var(--border-radius-primary);
    padding: 7px 12px;
    cursor: pointer;

    &.disabled{
      background-color: inherit;
      cursor: not-allowed;
    }

    &:hover{
      background-color: var(--sidebarItemBackground);
      border-radius: var(--border-radius-primary);
    }

    label, input{
      margin: 0;
      cursor: pointer;
    }
  }
}

/* phone number input */
.formField .react-tel-input .form-control.invalid-number:focus{
  background-color: inherit;
}
.react-tel-input .country-list .search-box {
  max-width: 100%;
  width: calc(100% - 20px);
}

.formField .react-tel-input .country-list {
  width: 250px;
}

.react-tel-input .country-list .country {
  font-size: 14px;
}

.form-control, .formField .react-tel-input .form-control {
  transition: none;
  background-color: var(--color-primary);
  color: var(--color-secondary);

  &:focus {
    box-shadow: none;
  }
}

.formField input.form-control:focus {
  &:valid ~ .flag-dropdown {
    border: 1px solid #77bee1 !important;
    border-right: 1px solid #cacaca !important;

  }
  
  &:invalid ~ .flag-dropdown, 
  ~ .flag-dropdown.invalid-number {
    border: 1px solid #f37f7f !important;
    border-right: 1px solid #cacaca !important;
  }
}


// image upload.

.imageSelection{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.default-images{
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  img{
    max-width: 100%;
    aspect-ratio: 24/9;
    object-fit: cover;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;

    &:hover{
      opacity: 1;
    }
  }
}

.imageSelection .selected-image{
  width: 100%;
  height: auto;
  aspect-ratio: 24 / 9;
  object-fit: cover;
  object-position: top;
  margin: 2rem auto;
}

.exhImageFunctionBtns{
  margin: 2rem auto;
}

.image-editor{
  z-index: 9999999999999999999;
}
/* Make sure the cropping area has enough height */
.image-editor .ReactCrop__crop-selection {
  aspect-ratio: 24/9;
  min-height: 200px; /* Set this to the desired cropping area height */
}



// switch checkbox

.switchCheckboxContainer{
  display: flex;
  align-items: center;
  // justify-content: center;
}

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchCheckboxLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 21px;
  background: var(--sidebarItemBackground) !important;
  border: var(--border);
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  margin: 0;

  &.checked{
    background-color: var(--background-color-primary-button) !important;
  }
}

.switchCheckboxLabel {
  .switchCheckboxButton {
    content: '';
    position: absolute;
    top: 2px;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transition: 0.2s;
    background: var(--background-color-primary-button) !important;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  &.checked .switchCheckboxButton{
    background-color: var(--color-primary-button) !important;
  }
}

.switchCheckbox:checked + .switchCheckboxLabel .switchCheckboxButton {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.switchCheckboxLabel:active .switchCheckboxButton {
  width: 30px;
}

.password-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    padding-right: 40px; // Space for the button
  }

  .password-toggle-btn {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
.poNumberFormField{
  max-width: 350px;
  margin: 24px 0;
}
