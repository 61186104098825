:root {
  --color-primary:#fff;
  --color-secondary: #000;
  --color-nav-link:#0000008c;
  --font-size-primary-heading:32px;
  --color-heading:#2497cf;
  --color-heading-hover:#0b5ed7;
  --text-transform-primary-heading:uppercase;
  --font-weight-primary-heading:normal;
  --font-weight-secondary-heading:500;
  --font-size-secondary-heading:20px;
  --line-height-secondary-heading: 28px;
  --font-size-paragraph:16px;
  --line-height-paragraph:1.5;
  --border:1px solid rgba(0, 0, 0, 0.125);
  --border-radius-primary: 2px;
  --bs-list-group-border-radius: 2px;
  --footer-height:50px;
  --box-shadow-modal: 0px 0px 28px 1px #00000054;
  --box-shadow-login: rgb(21 21 50 / 45%) 0px 0px 15px 2px;
  --box-shadow-floorplan: 0px 1px 4px rgba(0, 0, 0, 0.3);
  --background-color-primary-button: var(--color-heading);
  --background-color-primary-button-hover: var(--color-heading-hover);
  --color-primary-button: #fff;
  --btn-primary-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --btn-primary-hover-border: 1px solid rgba(128, 128, 128, 0.3490196078);

  --color-nav-link: rgba(0, 0, 0, 0.8);
  --color-nav-link-hover: rgba(0, 0, 0, 1);
  --color-label: var(--color-secondary);
  --sidebarItemBackground: #f4f4f4;

  --fontFamilyPrimary: 'Roboto', sans-serif;
  --fontFamilySecondary: 'Roboto', sans-serif;
  --defaultFontWeight: 300;

  --color-disabled: #707070;
  --background-color-disabled: #f2f2f2;

  --font-size-label:13px;
  --font-size-form-error:12px;
  --color-form-error:red;

  --color-div-hover: #f5f5f5;
  //card styling 
  --card-border: 2px solid var(--color-div-hover);
  --card-border-radius: 8px;
}

html, body {
  /* overflow: hidden; */
  // min-height: 100vh;
  min-width: 100%;
  background-color: var(--color-primary);
  font-family: var(--fontFamilySecondary);
  font-size: var(--font-size-paragraph);
  line-height: var(--line-height-paragraph);
  font-weight: var(--defaultFontWeight);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::before, &::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.rootWrapperContainer{
  width: 100%;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

img, svg{
  background-color: transparent;
}

p{
  color: inherit;
  background-color: inherit;
}
span {
  color: inherit;
  background-color: transparent;
}

::placeholder, .react-select__placeholder, .react-select__control .react-select__placeholder{
  font-size: 13px;
}

.formField .react-select__control .react-select__placeholder{
  letter-spacing: 0.6px;
  font-weight: initial;
}

.formField {
  > {
    input::placeholder, select::placeholder, textarea::placeholder {
      color: #969696;
    }
  }

  .react-tel-input input::placeholder {
    color: #969696;
  }
  .react-select__control .react-select__placeholder{
    z-index: 0;
    color: #969696;
  }
}

.bold{
  font-weight: bold;
}

.table{
  margin-bottom: 0;
}

.table > thead {
  position: sticky;
  // background-color: var(--sidebarItemBackground);
  top: -1px;
  z-index: 1;
}

.table-responsive {
  max-height: 500px;
}

th, td{
  border-bottom: var(--border);
}

th {
  // text-align: center;
  vertical-align: middle;
  font-weight: normal;
}

td {
  // text-align: center;
  vertical-align: middle;
}

.table>:not(caption)>*>*{
  padding: 12px;
  color: inherit;
  // background-color: inherit !important;
}

.table>:not(caption) th{
  padding-top: 0;
}

.asterisk {
  color: red;
}

.pagination-controls{
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination li:last-child .page-link{
  border-right: var(--border);
}

.page-link {
  background-color: var(--color-primary);
  color: var(--color-nav-link);
  border: var(--border);
  border-right: 0;
  outline: none !important;

  &:hover{
    background-color: var(--sidebarItemBackground);
    color: var(--color-nav-link-hover);
    border: var(--border);
    border-color: var(--color-nav-link-hover);
  }

  &:focus{
    background-color: var(--sidebarItemBackground);
    color: var(--color-nav-link-hover);
    box-shadow: none;
  }
}

.privacyPolicy table{
  border: var(--border) !important;
  tbody{
    border: var(--border) !important;
  }
  td{
    padding: 12px;
    border-right: var(--border) !important;
    border-bottom: var(--border) !important;
    border-top: var(--border) !important;
    border-left: var(--border) !important;
  }
}