:root {
  --border-primary:1px solid #ccc;
}

p {
  margin: 0;
}

.navbar-main {
  min-height: 5rem;
  background-color: var(--color-primary);
  // padding: 8px 20px;
}

.navbar-collapse{
  flex-grow: 0;
}

.navbar-nav .dropdown-menu .dropdown-item {
  &:active, &.active {
    color: var(--color-primary);
  }
}

.profileDropdown {
  cursor: pointer;
  padding-left: 14px;
  
  .nav-link{
    background-color: inherit;
    color: var(--color-nav-link);
    &:hover, &:active{
      background-color: inherit;
      color: var(--color-nav-link-hover);
    }
  }
}
.show-date, .show-location {
  font-size: 0.8em;
}
.show-date {    
  margin-right: 8px;
}

.site-header {
  position: fixed;
  // max-width: calc(1400px - 32px);
  max-width: 1400px;
  width: 100%;
  top: 0;
  z-index: 1001;
}

.navbar-nav {
  // width: 100%;
  align-items: center;
  // background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  margin: 0; 
  justify-content: end;
  gap: 2px;
  // flex-wrap: wrap;
  background-color: inherit;
}

.navbar-brand {
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
  font-size: var(--font-size-primary-heading);
  font-weight: 700;
  height: auto;
  color: var(--color-secondary);

  width: max-content;

  .navbarShowLogo{
    img{
      height: 2.5rem;
      width: auto;
      margin-right: 10px;
    }
  }

  &:hover, &:focus {
    text-decoration: none;
    color: var(--color-secondary);
  }
}

// .showDetails-navbar{
//   display: flex;
//   align-items: center;
// }

.navbarShowInfo {
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 8px;
} 

.dropdown-item{
  color: inherit;
  background-color: inherit;
  padding: 0;
}

.nav-link, .profile-name, .navbar-nav .show>.nav-link {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-nav-link);
  text-wrap: nowrap;

  &:hover, &:focus{
    color: var(--color-nav-link-hover);
  }
}

.navbar-nav .nav-link.active, .navLinkActive .profile-name{
  color: var(--color-nav-link-hover) !important;
  font-weight: bold;
}

.dropdown-toggle{
  display: flex;
  align-items: center;
}

.dropdown-toggle.nav-link {
  display: flex;
  align-items: center;
}

.profile-icon {
  width: 35px;
  height: auto;
  border-radius: 50%;
  margin-right: 5px;
  display: inline;
}

.profile-name {
  display: inline;
}

.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
  background-color: var(--color-primary);

  .dropdown-item:hover{
    background-color: var(--sidebarItemBackground);
    color: var(--color-nav-link-hover);
  }
}

.navbar-login-links{
  display: flex;
  text-wrap: nowrap;
}

.navbar-login-links .nav-link {
  display: inline-block;
}

div#basic-navbar-nav {
  margin-left: -12px;
  margin-right: -12px;
  background-color: var(--sidebarItemBackground);
  // padding: 0 32px;
  padding: 0 26px; // +6px from nav-link padding.
  width: 100%;
}
.showDetails-navbar{
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
}
.navbar>.container-fluid{
  padding: 0;
  flex-direction: column;
}

.navMenuHeader.row-2{
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 0 32px;
}

// .navLinksRight{
//   position: relative;
// }

.profileDropdown .statusContainer{
  position: absolute;
  top: -24px;
  right: 0;
}

@media (max-width: 768px) {
  // .navbarShowLogo{
  //   width: 10%;
  // }

  .showDetails-navbar, .showDetails-navbar div {
    width: 100%;
  }

  .navbar-brand{
    width: 100%;
    max-width: calc(100vw - 32px);

    .navbarShowName{
      font-size: var(--font-size-secondary-heading);
      white-space: break-spaces;
      display: flex;
      flex: 1;
    }
  
    .navbarShowLogo {
      width: fit-content;
    }
  }
}

@media screen and (max-width: 992px) {
  .navbar-main{
    padding: 0 12px;
  }
  .navbar > .container-fluid{
    flex-direction: row;
    // .showDetails-navbar{
    //   width: fit-content;
    // }
    .navbar-toggler{
      width: fit-content;
    }

    .navMenuHeader.row-2{
      padding: 0;
      width: 100%;

      .navbar-brand{
        width: 100%;
      }

      .navbarShowName {
        flex: 1;
      }
    }
  }
  .showDetails-navbar{
    flex-direction: column;
    align-items: flex-start;

    .navbarShowInfo{
      margin-left: 0 !important;
      margin-bottom: 12px;
      flex-direction: row;
      padding: 0;

      > *:first-child{
        margin-right: 12px;
      }
    }
  }

  .navbar-login-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  button.dropdown-item.nav-link, .navbar-nav a.nav-link {
    padding: 7px 12px !important;
  }
}

@media screen and (max-width: 576px) {
  .navbar-nav{
    align-items: center;
  }
  .navbar-main{
    padding: 8px 12px;
  }
  .navbar-toggler{
    padding: 0;
  }
}


@media (min-width: 992px) {
  .navbar-expand-lg{
    flex-wrap: nowrap;
    justify-content: start;
  }
  .navbar-expand-lg.showHamburgerMenu{
    flex-wrap: wrap;
    justify-content: center;

    .navbar-main div#basic-navbar-nav{
      margin: 0 0;
    }

    .navbar-main .navbar-nav {
      align-items: center;
      width: 100%;
      text-align: center;
    }
  }

  .navbar-expand-lg .navbar-nav.showHamburgerMenu{
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    div#basic-navbar-nav:not(.show){
      display: none !important;
      height: 0;
      transition-duration: .2s;
    }
  }

  .showHamburgerMenu div#basic-navbar-nav.show{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    animation-duration: 0.3s;

    .navbar-nav{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;

      .nav-item{
        width: 100%;

        .nav-link:hover, .nav-link.active, .navLink:active{
          background-color: var(--sidebarItemBackground);
        }

        .dropdown-toggle{
          justify-content: center;
        }
      }
    }
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .navbar-toggler.show,
  .showHamburgerMenu .navbar-toggler {
    display: block;
  }

  .showHamburgerMenu{
    .navbar-login-links{
      flex-direction: column;
    }
    div#basic-navbar-nav.navbar-collapse:not(.show){
      display: none !important;
      height: 0;
    }
  
  }
  #googleTranslateElement, #googleTranslateElement .goog-te-gadget-simple{
    width: 100%;

    &:hover{
      background-color: var(--sidebarItemBackground);
    }
  }

  iframe.skiptranslate{
    // left: 50% !important;
    left: 85% !important;
  }
}