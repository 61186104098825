// /* ExternalProfile.css */

// /* Profile Wrapper */
// .profileWrapper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   padding: 20px;
// }

.profileName{
  margin-bottom: 0;
}

/* Profile Avatar */
.profile-avatar {
  display: inline-block;
  height: 60px;
  width: 60px;
  background-color: #f7f7f7;
  border-radius: 50%;
  box-sizing: border-box;
  float: left;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  position: relative;
  background-position: -36px;
  margin-right: 12px;

  .profileImage{
    background-size: 100% 100%;
    // filter: blur(2px);
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  svg{
    stroke: var(--color-secondary);
  }
}

.profile-info {
  margin-bottom: 25px;
}

.profile-info .invoice-formEdit{
  float: right;
}

.profile-topSection{
  display: flex;

  > div{
    flex: 1;
  }

  .invoice-formEdit{
    float: left;
  }
// edit button styling
  // .button-primary{
  //   background-color: inherit;
  // }
}

.userProfileForm{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;

  > div{
    flex: 1;

    :last-child{
      margin-bottom: 0;
    }
  }

  .form-group{
    margin-right: 20px;
    input{
      width: 320px;
      max-width: 100%;
    }
  }
}


// Media Screen lessthan 422px
@media screen and (max-width: 422px) {
  .userProfileForm{
    .form-group{
      input{
        width: 250px;
      }
    }
  }
}


